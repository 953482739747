<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon> GUIA SADT LIMPA
  </v-btn>
</template>

<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc;
export default {
  name: "sadt",
  data: () =>
    withCRUDUtils({
      loading: false,
      orcamento: [],
      procedimentos: [],
      prorrogacao: [],
      medico: "",
      qtd: [],
      item: "",
      quant: "",
      signed: ""
    }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async getPDF() {
      this.loading = true;
      // this.directoryHandle = await UtilsPDF.selectDirectory();
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProcedimentos(),
        ]);
        await this.saveData();
        this.qtd = []
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
      }
    },
    async drawHeader(doc) {
      const data = this.orcamento;
      const medicoPaciente = this.medico;
      // Logomarca do Convênio
      try {
        const imageUrl = `https://api.gssma.com.br/media/${data?.convenio_logomarca}`;
        doc.addImage(imageUrl, 5, 5, 22, 12);
      } catch (error) {
        this.$toast.error(
          "Falha ao adicionar a imagem da Logomarca da Empresa. Verifique o Cadastro da Empresa",
          error
        );
      }
      // Carimbo do Médico do paciente
      try {
        let imageUrl = `${medicoPaciente?.carimbo}`;
        // console.log('Carimbo do médico --> ',imageUrl)
        imageUrl = imageUrl.replace("http", "https");
        doc.addImage(imageUrl, 250, 57, 22, 12);
        // const params = this.imageUrl.split("://");
        //   if (params[0] === "http") {
        //     imageUrl = imageUrl.replace("http", "https");
        //   }
        // doc.addImage(imageUrl, 150, 195, 22, 12);
      } catch (error) {
        this.$toast.error(
          "Falha ao adicionar a imagem do Carimbo do Médico Responsável. Verifique o Cadastro do Médico",
          error
        );
      }

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10); // Tamanho da fonte, ajuste conforme necessário
      doc.text(
        "GUIA DE SERVIÇO PROFISSIONAL / SERVIÇO AUXILIAR DE DIAGNÓSTICO E TERAPIA - SP/SADT",
        140,
        15,
        { align: "center" }
      );
      doc.setFontSize(7);
      doc.text("2 - Nº Guia do Prestador:", 250, 15, { align: "center" });
      doc.text(`${data.nr_guia_prestador ?? ''}`, 271, 15, { align: "center" });
    },
    async basePDF(doc, pageNumber) {
      doc.setPage(pageNumber);

      const data = this.orcamento;
      const margin = 2;

      // Calcule as dimensões da borda interna
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const width = pageWidth - 2 * margin;
      const height = pageHeight - 2 * margin;

      // Desenha a borda interna
      doc.setDrawColor(0); // Cor da borda preta, altere conforme necessário
      doc.setLineWidth(0.5); // Espessura da linha, altere conforme necessário
      doc.rect(margin, margin, width, height);

      await this.drawHeader(doc);

      // Defina a posição e o tamanho do retângulo ao redor do campo "1 - Registro ANS"
      let inicioTexto = 3; // posição X onde o texto começa
      let fimTexto = 20; // posição Y onde o texto começa
      let larguraRetangulo = 28; // largura do retângulo
      let alturaRetangulo = 8; // altura do retângulo
      const raioBorda = 1;
      // Desenhe o retângulo
      doc.setDrawColor(0); // Cor da borda preta
      doc.setLineWidth(0.1); // Espessura da linha
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      // Adicione o texto centralizado no retângulo
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7); // Tamanho da fonte para o texto dentro do retângulo
      let textOffset = (alturaRetangulo - 6) / 2; // Centraliza o texto verticalmente dentro do retângulo
      doc.text(
        "1 - Registro ANS",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      ); // Ajuste o segundo valor de Y conforme necessário para centralizar

      inicioTexto = 32; // posição X(largura) onde o texto começa
      fimTexto = 20; //posição Y(altura) onde o texto começa
      larguraRetangulo = 63; // largura do retângulo
      doc.setDrawColor(0); // Cor da borda preta
      doc.setLineWidth(0.1); // Espessura da linha
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "3 - Número da Guia Principal",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );
      doc.text(
        `${data.nr_guia_operador ? data.nr_guia_operador : ""}`,
        inicioTexto + 1,
        fimTexto + textOffset + 6
      );

      inicioTexto = 96;
      fimTexto = 20;
      larguraRetangulo = 35;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "4 - Data da Autorização",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 132;
      fimTexto = 20;
      larguraRetangulo = 55;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text("5 - Senha", inicioTexto + 1, fimTexto + textOffset + 1);


      inicioTexto = 188;
      fimTexto = 20;
      larguraRetangulo = 45;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "6 - Data da Validade da Senha",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 234;
      fimTexto = 20;
      larguraRetangulo = 60;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "7 - Número da Guia Atribuida pela Operadora",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      // Dados do Beneficiário
      let inicioFundo = margin + 1;
      let fimFundo = 29;
      let larguraFundo = pageWidth - 3 * margin;
      let alturaFundo = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        inicioFundo,
        fimFundo,
        larguraFundo,
        alturaFundo,
        raioBorda,
        raioBorda,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      textOffset = fimFundo + alturaFundo / 2 + 0.5;
      doc.text("Dados do Beneficiário", inicioFundo + 2, textOffset);

      inicioTexto = 3;
      fimTexto = 35;
      larguraRetangulo = 55;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "8 - Número da Carteira",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 59;
      fimTexto = 35;
      larguraRetangulo = 50;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "9 - Validade da Carteira",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 110;
      fimTexto = 35;
      larguraRetangulo = 92;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text("10 - Nome", inicioTexto + 1, fimTexto + textOffset + 1);

      inicioTexto = 203;
      fimTexto = 35;
      larguraRetangulo = 50;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "11 - Carteira Nacional de Saúde",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 254;
      fimTexto = 35;
      larguraRetangulo = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "12 - Atendimento a RN",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );
      doc.text("N", inicioTexto + 1, fimTexto + textOffset + 6);

      // Dados Solicitante
      inicioFundo = margin + 1; // Início do fundo, geralmente a margem esquerda do documento
      fimFundo = 44; // Posição Y onde o fundo começa
      larguraFundo = pageWidth - 3 * margin; // A largura do fundo é a largura da página menos as margens dos dois lados
      alturaFundo = 5; // Altura do fundo, ajuste conforme necessário
      doc.setFillColor(173, 216, 230); // Cor azul claro
      doc.roundedRect(
        inicioFundo,
        fimFundo,
        larguraFundo,
        alturaFundo,
        raioBorda,
        raioBorda,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7); // Tamanho da fonte para o texto dentro do fundo colorido
      doc.setTextColor(0, 0, 0); // Defina a cor do texto para preto
      textOffset = fimFundo + alturaFundo / 2 + 0.5;
      doc.text("Dados do Solicitante", inicioFundo + 2, textOffset);

      inicioTexto = 3;
      fimTexto = 50;
      larguraRetangulo = 55;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "13 - Código da Operadora",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 59;
      fimTexto = 50;
      larguraRetangulo = 235;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "14 - Nome do Contratado",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 3;
      fimTexto = 59;
      larguraRetangulo = 71;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "15 - Nome do Profissional Solicitante",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 75;
      fimTexto = 59;
      larguraRetangulo = 32;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "16 - Conselho Profissional",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 108;
      fimTexto = 59;
      larguraRetangulo = 32;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "17 - Número no Conselho",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 141;
      fimTexto = 59;
      larguraRetangulo = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text("18 - UF", inicioTexto + 1, fimTexto + textOffset + 1);

      inicioTexto = 172;
      fimTexto = 59;
      larguraRetangulo = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "19 - Código CBO",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 203;
      fimTexto = 59;
      larguraRetangulo = 91;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.text(
        "20 - Assinatura do Profissional Solicitante",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      // Dados da Solicitação / Procedimento ou Itens Assistenciais Solicitados
      inicioFundo = margin + 1; // Início do fundo, geralmente a margem esquerda do documento
      fimFundo = 68; // Posição Y onde o fundo começa
      larguraFundo = pageWidth - 3 * margin; // A largura do fundo é a largura da página menos as margens dos dois lados
      alturaFundo = 5; // Altura do fundo, ajuste conforme necessário
      doc.setFillColor(173, 216, 230); // Cor azul claro
      doc.roundedRect(
        inicioFundo,
        fimFundo,
        larguraFundo,
        alturaFundo,
        raioBorda,
        raioBorda,
        "F"
      ); // 'F' para preenchimento
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7); // Tamanho da fonte para o texto dentro do fundo colorido
      doc.setTextColor(0, 0, 0); // Defina a cor do texto para preto
      textOffset = fimFundo + alturaFundo / 2 + 0.5;
      doc.text(
        "Dados da Solicitação / Procedimento ou Itens Assistenciais Solicitados",
        inicioFundo + 2,
        textOffset
      );

      inicioTexto = 3;
      fimTexto = 74;
      larguraRetangulo = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "21 - Caráter de Atendimento",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );
      doc.text("1", inicioTexto + 1, fimTexto + textOffset + 6);

      inicioTexto = 44;
      fimTexto = 74;
      larguraRetangulo = 50;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "22 - Data da Solicitação",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 95;
      fimTexto = 74;
      larguraRetangulo = 199;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "23 - Indicação Clínica",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 3;
      fimTexto = 83;
      larguraRetangulo = 291;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        13,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text("24 - Tabela", inicioTexto + 4, fimTexto + textOffset + 1);
      doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);

      doc.text(
        "25 - Código do Procedimento",
        inicioTexto + 22,
        fimTexto + textOffset + 1
      );

      doc.text(
        "26 - Descrição",
        inicioTexto + 60,
        fimTexto + textOffset + 1
      );

      doc.text(
        "27 - Qtd. Solicitada",
        inicioTexto + 240,
        fimTexto + textOffset + 1
      );

      doc.text(
        "28 - Qtd. Autorizada",
        inicioTexto + 265,
        fimTexto + textOffset + 1
      );

      // Dados do Contratado Executante
      inicioFundo = margin + 1;
      fimFundo = 97;
      larguraFundo = pageWidth - 3 * margin;
      alturaFundo = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        inicioFundo,
        fimFundo,
        larguraFundo,
        alturaFundo,
        raioBorda,
        raioBorda,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      textOffset = fimFundo + alturaFundo / 2 + 0.5;
      doc.text(
        "Dados do Contratado Executante",
        inicioFundo + 2,
        textOffset
      );

      inicioTexto = 3;
      fimTexto = 103;
      larguraRetangulo = 45;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "30 - Código na Operadora",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 49;
      fimTexto = 103;
      larguraRetangulo = 214;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "31 - Nome do Contratado",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 264;
      fimTexto = 103;
      larguraRetangulo = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "40 - Código CNES",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      // Dados do Atendimento
      inicioFundo = margin + 1;
      fimFundo = 112;
      larguraFundo = pageWidth - 3 * margin;
      alturaFundo = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        inicioFundo,
        fimFundo,
        larguraFundo,
        alturaFundo,
        raioBorda,
        raioBorda,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      textOffset = fimFundo + alturaFundo / 2 + 0.5;
      doc.text("Dados do Atendimento", inicioFundo + 2, textOffset);

      inicioTexto = 3;
      fimTexto = 118;
      larguraRetangulo = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "32 - Tipo de Atendimento",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );
      doc.text("6", inicioTexto + 1, fimTexto + textOffset + 6);

      inicioTexto = 34;
      fimTexto = 118;
      larguraRetangulo = 80;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "33 - Indicação de Acidente (acidente ou doença relacionada)",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );
      doc.text("9", inicioTexto + 1, fimTexto + textOffset + 6);

      inicioTexto = 115;
      fimTexto = 118;
      larguraRetangulo = 30;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "34 - Tipo de Consulta",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 146;
      fimTexto = 118;
      larguraRetangulo = 70;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "35 - Motivo de Encerramento do Atendimento",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      // Dados da Execução / Procedimentos e Exames Realizados
      inicioFundo = margin + 1;
      fimFundo = 127;
      larguraFundo = pageWidth - 3 * margin;
      alturaFundo = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        inicioFundo,
        fimFundo,
        larguraFundo,
        alturaFundo,
        raioBorda,
        raioBorda,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      textOffset = fimFundo + alturaFundo / 2 + 0.5;
      doc.text(
        "Dados da Execução / Procedimentos e Exames Realizados",
        inicioFundo + 2,
        textOffset
      );

      inicioTexto = 3;
      fimTexto = 133;
      larguraRetangulo = 291;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        14,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text("36 - Data", inicioTexto + 3, fimTexto + textOffset + 1);
      doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);
      doc.text("", inicioTexto + 4, fimTexto + textOffset + 6);
      doc.text(
        "37 - Hora Inicial",
        inicioTexto + 22,
        fimTexto + textOffset + 1
      );
      doc.text(``, inicioTexto + 22, fimTexto + textOffset + 6);
      doc.text(
        "38 - Hora Final",
        inicioTexto + 40,
        fimTexto + textOffset + 1
      );
      doc.text(``, inicioTexto + 40, fimTexto + textOffset + 6);
      doc.text(
        "39 - Tabela",
        inicioTexto + 60,
        fimTexto + textOffset + 1
      );

      doc.text(
        "40 - Código Procedimento",
        inicioTexto + 75,
        fimTexto + textOffset + 1
      );

      doc.text(
        "41 - Descrição",
        inicioTexto + 110,
        fimTexto + textOffset + 1
      );

      doc.text("42 - Qtde", inicioTexto + 185, fimTexto + textOffset + 1);

      doc.text("43 - Via", inicioTexto + 205, fimTexto + textOffset + 1);

      doc.text(
        "44 - Fator Red./Acresc.",
        inicioTexto + 220,
        fimTexto + textOffset + 1
      );

      doc.text(
        "45 - Valor Unitário",
        inicioTexto + 245,
        fimTexto + textOffset + 1
      );

      doc.text(
        "46 - Valor Total",
        inicioTexto + 270,
        fimTexto + textOffset + 1
      );

      // Identificação do(s) Profissional(is) Executante(s)
      inicioFundo = margin + 1;
      fimFundo = 148;
      larguraFundo = pageWidth - 3 * margin;
      alturaFundo = 5;
      doc.setFillColor(173, 216, 230);
      doc.roundedRect(
        inicioFundo,
        fimFundo,
        larguraFundo,
        alturaFundo,
        raioBorda,
        raioBorda,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      textOffset = fimFundo + alturaFundo / 2 + 0.5;
      doc.text(
        "Identificação do(s) Profissional(is) Executante(s)",
        inicioFundo + 2,
        textOffset
      );

      inicioTexto = 3;
      fimTexto = 154;
      larguraRetangulo = 291;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        12,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "48 - Seq.Ref",
        inicioTexto + 2,
        fimTexto + textOffset + 1
      );
      doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);
      doc.text(
        "49 - Grau Part",
        inicioTexto + 20,
        fimTexto + textOffset + 1
      );

      doc.text(
        "50 - Código na Operadora / CPF",
        inicioTexto + 40,
        fimTexto + textOffset + 1
      );

      doc.text(
        "51 - Nome do Profissional",
        inicioTexto + 80,
        fimTexto + textOffset + 1
      );

      doc.text(
        "52 - Conselho profissional",
        inicioTexto + 185,
        fimTexto + textOffset + 1
      );

      doc.text(
        "53 - Número no Conselho",
        inicioTexto + 220,
        fimTexto + textOffset + 1
      );

      doc.text("54 - UF", inicioTexto + 250, fimTexto + textOffset + 1);

      doc.text(
        "55 - Código CBO",
        inicioTexto + 270,
        fimTexto + textOffset + 1
      );

      inicioTexto = 3;
      fimTexto = 167;
      larguraRetangulo = 291;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        16,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "56 - Data Realização Procedimento em Série           57 - Assinatura do Beneficiário ou Responsável]",
        inicioTexto + 2,
        fimTexto + textOffset + 1
      );

      // Assinaturas dos Pacientes
      inicioTexto = 3;
      const incrementoHorizontal = 60;
      const yPositionOdd = 173;
      const yPositonEven = 180;
      doc.text("1º",
        inicioTexto + 2,
        yPositionOdd
      );

      doc.text("2º",
        inicioTexto + 2,
        yPositonEven
      );

      let evenNumber = 62;
      let oddNumber = 62;

      for (let i = 1; i <= 10; i++) {
        if (i % 2 !== 0 && i > 2) { // Números ímpares
          doc.text(`${i}º`,
            evenNumber,
            yPositionOdd
          );
          evenNumber += incrementoHorizontal
        } else if (i % 2 === 0 && i > 2) { // Números pares
          doc.text(`${i}º`,
            oddNumber,
            yPositonEven
          );
          oddNumber += incrementoHorizontal
        }
      }

      // Campo de Observação
      inicioFundo = margin + 1;
      fimFundo = 184;
      larguraFundo = pageWidth - 3 * margin;
      alturaFundo = 5;
      doc.setFillColor(173, 216, 230);
      doc.setDrawColor(0);
      doc.setLineWidth(0.2);
      doc.roundedRect(
        inicioFundo,
        fimFundo,
        larguraFundo,
        alturaFundo,
        raioBorda,
        raioBorda,
        "F"
      );
      doc.setFont("helvetica", "bold");
      doc.setFontSize(7);
      doc.setTextColor(0, 0, 0);
      textOffset = fimFundo + alturaFundo / 2 + 0.5;
      doc.text(
        "58 - Observação / Justificativa:  " + "",
        inicioFundo + 2,
        textOffset
      );

      // Parte de Resumo Valores
      inicioTexto = margin + 1;
      fimTexto = 190;
      larguraRetangulo = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "59 - Total Procedimentos",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 44;
      fimTexto = 190;
      larguraRetangulo = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "60 - Total Taxas e Alugueis",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 85;
      fimTexto = 190;
      larguraRetangulo = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "61 - Total Materiais",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 126;
      fimTexto = 190;
      larguraRetangulo = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "62 - Total de OPME",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 167;
      fimTexto = 190;
      larguraRetangulo = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "63 - Total de Medicamentos",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 208;
      fimTexto = 190;
      larguraRetangulo = 40;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "64 - Total de Gases Medicinais",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 249;
      fimTexto = 190;
      larguraRetangulo = 45;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "65 - Total Geral da Guia",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = margin + 1;
      fimTexto = 199;
      larguraRetangulo = 90;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "66 - Assinatura do Responsável pela Autorização",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 94;
      fimTexto = 199;
      larguraRetangulo = 100;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "67 - Assinatura do Beneficiário ou Responsável",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );

      inicioTexto = 195;
      fimTexto = 199;
      larguraRetangulo = 99;
      doc.setDrawColor(0);
      doc.setLineWidth(0.1);
      doc.roundedRect(
        inicioTexto,
        fimTexto,
        larguraRetangulo,
        alturaRetangulo,
        raioBorda,
        raioBorda
      );
      textOffset = (alturaRetangulo - 6) / 2;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        "68 - Assinatura do Contratado",
        inicioTexto + 1,
        fimTexto + textOffset + 1
      );
    },
    async saveData() {
      this.$toast.success(
        `Será(ão) gerado(s) ${this.qtd.length} PDF(s). Aguarde, por favor.`
      );
      for (const procedimento of this.qtd) {
        // Encontra o primeiro procedimento com o procedimento_id correspondente e que tenha um nome de profissional.
        const item = this.procedimentos.find(
          (p) => p.procedimento.procedimento_id === procedimento.procedimento_id
        );

        this.item = item

        if (item) {
          try {
            const doc = new jsPDF({
              orientation: "landscape",
              format: "a4",
              compress: true,
            });

            const data = this.orcamento;
            const medicoPaciente = this.medico;
            const margin = 2;

            // Defina a posição e o tamanho do retângulo ao redor do campo "1 - Registro ANS"
            let inicioTexto = 3; // posição X onde o texto começa
            let fimTexto = 20; // posição Y onde o texto começa
            let larguraRetangulo = 28; // largura do retângulo
            let alturaRetangulo = 8; // altura do retângulo
            const raioBorda = 1;
            // Desenhe o retângulo
            // Adicione o texto centralizado no retângulo
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7); // Tamanho da fonte para o texto dentro do retângulo
            let textOffset = (alturaRetangulo - 6) / 2; // Centraliza o texto verticalmente dentro do retângulo

            doc.text(
              `${data.convenio_ans ? data.convenio_ans : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            ); // Ajuste o segundo valor de Y conforme necessário para centralizar

            inicioTexto = 32; // posição X(largura) onde o texto começa
            fimTexto = 20; //posição Y(altura) onde o texto começa
            larguraRetangulo = 63; // largura do retângulo
            textOffset = (alturaRetangulo - 6) / 2;

            doc.text(
              `${data.nr_guia_operador ? data.nr_guia_operador : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 96;
            fimTexto = 20;
            larguraRetangulo = 35;
            textOffset = (alturaRetangulo - 6) / 2;

            // 4 - Data Autorização
            doc.text(
              `${UtilsPDF.formatDate(data?.senha_data_inicio)}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 132;
            fimTexto = 20;
            larguraRetangulo = 55;
            textOffset = (alturaRetangulo - 6) / 2;

            doc.text(
              `${data.senha ? data.senha : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 188;
            fimTexto = 20;
            larguraRetangulo = 45;
            textOffset = (alturaRetangulo - 6) / 2;

            // 6 - Data Validade da Senha
            doc.text(
              `${UtilsPDF.formatDate(data?.senha_data_validade)}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 234;
            fimTexto = 20;
            larguraRetangulo = 60;
            textOffset = (alturaRetangulo - 6) / 2;

            doc.text(
              `${data.nr_guia_operador ? data.nr_guia_operador : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            // Dados do Beneficiário
            let fimFundo = 29;
            let alturaFundo = 5;

            doc.setFont("helvetica", "bold");
            doc.setFontSize(7);
            doc.setTextColor(0, 0, 0);
            textOffset = fimFundo + alturaFundo / 2 + 0.5;


            inicioTexto = 3;
            fimTexto = 35;
            larguraRetangulo = 55;
            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text(
              `${data.nr_carteirinha ? data.nr_carteirinha : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 59;
            fimTexto = 35;
            larguraRetangulo = 50;

            textOffset = (alturaRetangulo - 6) / 2;

            // 9 - Validade da Carteira
            doc.text(
              `${UtilsPDF.formatDate(data?.validade)}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 110;
            fimTexto = 35;
            larguraRetangulo = 92;

            textOffset = (alturaRetangulo - 6) / 2;

            // 10 - Nome
            doc.text(
              `${data.paciente ? data.paciente : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 203;
            fimTexto = 35;
            larguraRetangulo = 50;

            textOffset = (alturaRetangulo - 6) / 2;

            // 11 - Carteira Nacional de Saúde
            doc.text(
              `${data.paciente_carteira_sus ? data.paciente_carteira_sus : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 254;
            fimTexto = 35;
            larguraRetangulo = 40;

            textOffset = (alturaRetangulo - 6) / 2;

            doc.text("N", inicioTexto + 1, fimTexto + textOffset + 6);

            // Dados Solicitante
            fimFundo = 44; // Posição Y onde o fundo começa
            alturaFundo = 5; // Altura do fundo, ajuste conforme necessário

            doc.setFont("helvetica", "bold");
            doc.setFontSize(7); // Tamanho da fonte para o texto dentro do fundo colorido
            doc.setTextColor(0, 0, 0); // Defina a cor do texto para preto
            textOffset = fimFundo + alturaFundo / 2 + 0.5;

            inicioTexto = 3;
            fimTexto = 50;
            larguraRetangulo = 55;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text(
              `${data.convenio_codigo ? data.convenio_codigo : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 59;
            fimTexto = 50;
            larguraRetangulo = 235;

            doc.text(
              `${data.empresa_nome ? data.empresa_nome : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 3;
            fimTexto = 59;
            larguraRetangulo = 71;

            doc.text(
              `${medicoPaciente.first_name
                ? medicoPaciente.first_name + " " + medicoPaciente.last_name
                : ""
              }`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 75;
            fimTexto = 59;
            larguraRetangulo = 32;

            doc.text(
              `${medicoPaciente.conselho ? medicoPaciente.conselho.nome : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 108;
            fimTexto = 59;
            larguraRetangulo = 32;

            doc.text(
              `${medicoPaciente.nconselho ? medicoPaciente.nconselho : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 141;
            fimTexto = 59;
            larguraRetangulo = 30;

            doc.text(
              `${medicoPaciente.uf_inscricao_conselho
                ? medicoPaciente.uf_inscricao_conselho.sigla
                : ""
              }`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 172;
            fimTexto = 59;
            larguraRetangulo = 30;

            doc.text(
              `${medicoPaciente.especialidade
                ? medicoPaciente.especialidade.cbo
                : ""
              }`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 203;
            fimTexto = 59;
            larguraRetangulo = 91;
            doc.text("", inicioTexto + 1, fimTexto + textOffset + 6);

            // Dados da Solicitação / Procedimento ou Itens Assistenciais Solicitados
            fimFundo = 68; // Posição Y onde o fundo começa
            alturaFundo = 5; // Altura do fundo, ajuste conforme necessário

            doc.setFont("helvetica", "bold");
            doc.setFontSize(7); // Tamanho da fonte para o texto dentro do fundo colorido


            inicioTexto = 3;
            fimTexto = 74;
            larguraRetangulo = 40;

            doc.text("1", inicioTexto + 1, fimTexto + textOffset + 6);

            inicioTexto = 44;
            fimTexto = 74;
            larguraRetangulo = 50;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            // 22- Data da Solicitação
            doc.text(
              `${UtilsPDF.formatDate(data?.senha_data_inicio)}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 95;
            fimTexto = 74;
            larguraRetangulo = 199;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text(
              `${data?.cid}` + ` -- ` + `${data?.cid_descricao}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 3;
            fimTexto = 83;
            larguraRetangulo = 291;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);
            doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);
            doc.text(
              `${item?.procedimento?.tabela_tabela ?? ''}`,
              inicioTexto + 4,
              fimTexto + textOffset + 6
            );

            doc.text(
              `${item.procedimento?.codigo ?? ''}`,
              inicioTexto + 22,
              fimTexto + textOffset + 6
            );
            
            const nomeProcedimento = item?.procedimento?.nomecomercial && item.procedimento.nomecomercial.trim() !== "" ? `${item.procedimento.procedimento} - (${item?.procedimento?.nomecomercial})` : item.procedimento.procedimento;
            doc.text(
              `${nomeProcedimento}`,
              inicioTexto + 60,
              fimTexto + textOffset + 6
            );

            const qtd = procedimento.qtd
            this.quant = qtd

            doc.text(
              `${qtd ? qtd : ""}`,
              inicioTexto + 240,
              fimTexto + textOffset + 6
            );

            doc.text(
              `${qtd ? qtd : ""}`,
              inicioTexto + 265,
              fimTexto + textOffset + 6
            );

            // Dados do Contratado Executante
            fimFundo = 97;
            alturaFundo = 5;

            doc.setFont("helvetica", "bold");
            doc.setFontSize(7);
            doc.setTextColor(0, 0, 0);
            textOffset = fimFundo + alturaFundo / 2 + 0.5;

            inicioTexto = 3;
            fimTexto = 103;
            larguraRetangulo = 45;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text(
              `${data.convenio_codigo ? data.convenio_codigo : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 49;
            fimTexto = 103;
            larguraRetangulo = 214;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text(
              `${data.empresa_nome ? data.empresa_nome : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            inicioTexto = 264;
            fimTexto = 103;
            larguraRetangulo = 30;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text(
              `${data.empresa_cnes ? data.empresa_cnes : ""}`,
              inicioTexto + 1,
              fimTexto + textOffset + 6
            );

            // Dados do Atendimento
            fimFundo = 112;
            alturaFundo = 5;

            doc.setFont("helvetica", "bold");
            doc.setFontSize(7);
            doc.setTextColor(0, 0, 0);
            textOffset = fimFundo + alturaFundo / 2 + 0.5;

            inicioTexto = 3;
            fimTexto = 118;
            larguraRetangulo = 30;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("6", inicioTexto + 1, fimTexto + textOffset + 6);

            inicioTexto = 34;
            fimTexto = 118;
            larguraRetangulo = 80;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("9", inicioTexto + 1, fimTexto + textOffset + 6);

            inicioTexto = 115;
            fimTexto = 118;
            larguraRetangulo = 30;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 6);

            inicioTexto = 146;
            fimTexto = 118;
            larguraRetangulo = 70;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 6);

            // Dados da Execução / Procedimentos e Exames Realizados
            fimFundo = 127;
            alturaFundo = 5;

            doc.setFont("helvetica", "bold");
            doc.setFontSize(7);
            doc.setTextColor(0, 0, 0);
            textOffset = fimFundo + alturaFundo / 2 + 0.5;

            inicioTexto = 3;
            fimTexto = 133;
            larguraRetangulo = 291;
            doc.setDrawColor(0);
            doc.setLineWidth(0.1);
            doc.roundedRect(
              inicioTexto,
              fimTexto,
              larguraRetangulo,
              14,
              raioBorda,
              raioBorda
            );
            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);
            doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);
            doc.text("", inicioTexto + 4, fimTexto + textOffset + 6);
            doc.text(``, inicioTexto + 22, fimTexto + textOffset + 6);
            doc.text(``, inicioTexto + 40, fimTexto + textOffset + 6);
            doc.text("", inicioTexto + 60, fimTexto + textOffset + 6);

            doc.text(``, inicioTexto + 185, fimTexto + textOffset + 6);
            doc.text("", inicioTexto + 205, fimTexto + textOffset + 6);
            doc.text("", inicioTexto + 220, fimTexto + textOffset + 6);
            doc.text("", inicioTexto + 245, fimTexto + textOffset + 6);
            doc.text("", inicioTexto + 270, fimTexto + textOffset + 6);

            // Identificação do(s) Profissional(is) Executante(s)
            fimFundo = 148;
            alturaFundo = 5;

            doc.setFont("helvetica", "bold");
            doc.setFontSize(7);
            doc.setTextColor(0, 0, 0);
            textOffset = fimFundo + alturaFundo / 2 + 0.5;


            inicioTexto = 3;
            fimTexto = 154;
            larguraRetangulo = 291;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);
            doc.text("", inicioTexto + 4, fimTexto + textOffset + 6);
            doc.text("", inicioTexto + 20, fimTexto + textOffset + 6);

            doc.text(
              `${""}`,
              inicioTexto + 270,
              fimTexto + textOffset + 6
            );

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            // Campo de Observação
            fimFundo = 184;
            alturaFundo = 5;

            doc.setFont("helvetica", "bold");
            doc.setFontSize(7);
            doc.setTextColor(0, 0, 0);
            textOffset = fimFundo + alturaFundo / 2 + 0.5;

            // Parte de Resumo Valores
            inicioTexto = margin + 1;
            fimTexto = 190;
            larguraRetangulo = 40;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = 44;
            fimTexto = 190;
            larguraRetangulo = 40;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = 85;
            fimTexto = 190;
            larguraRetangulo = 40;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = 126;
            fimTexto = 190;
            larguraRetangulo = 40;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = 167;
            fimTexto = 190;
            larguraRetangulo = 40;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = 208;
            fimTexto = 190;
            larguraRetangulo = 40;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = 249;
            fimTexto = 190;
            larguraRetangulo = 45;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = margin + 1;
            fimTexto = 199;
            larguraRetangulo = 90;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = 94;
            fimTexto = 199;
            larguraRetangulo = 100;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            inicioTexto = 195;
            fimTexto = 199;
            larguraRetangulo = 99;

            textOffset = (alturaRetangulo - 6) / 2;
            doc.setFont("helvetica", "normal");
            doc.setFontSize(7);

            doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

            const numberOfPages = doc.internal.getNumberOfPages();

            for (let i = 1; i <= numberOfPages; i++) {
              inicioTexto = 3;
              fimTexto = 133;
              larguraRetangulo = 291;

              // Chama basePDF para cada página
              await this.basePDF(doc, i);
              if (numberOfPages > 1) await this.baseData(doc, i);
            }

            const blob = doc.output("blob");
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;

            // Mantém o mesmo nome de arquivo
            const paciente = this.orcamento.paciente;
            const convenio = this.orcamento.convenio;
            a.download = `Guia SADT - ${item.procedimento.procedimento} - ${paciente} - ${convenio} - Período ${UtilsPDF.formatDate(data.data_inicio)} até ${UtilsPDF.formatDate(data.data_fim)}.pdf`;

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          } catch (error) {
            console.error("Erro ao salvar o arquivo:", error);
            this.$toast.error("Erro ao salvar o arquivo: " + error.message);
          }
        }
      }
      this.$toast.success("Download do(s) arquivo(s) realizado(s) com sucesso!");
    },
    async baseData(doc, pageNumber) {
      doc.setPage(pageNumber);

      this.loading = true;
      const item = this.item;

      if (item) {
        try {
          const data = this.orcamento;
          const medicoPaciente = this.medico;
          const margin = 2;

          // Defina a posição e o tamanho do retângulo ao redor do campo "1 - Registro ANS"
          let inicioTexto = 3; // posição X onde o texto começa
          let fimTexto = 20; // posição Y onde o texto começa
          let larguraRetangulo = 28; // largura do retângulo
          let alturaRetangulo = 8; // altura do retângulo
          const raioBorda = 1;
          // Desenhe o retângulo
          // Adicione o texto centralizado no retângulo
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7); // Tamanho da fonte para o texto dentro do retângulo
          let textOffset = (alturaRetangulo - 6) / 2; // Centraliza o texto verticalmente dentro do retângulo

          doc.text(
            `${data.convenio_ans ? data.convenio_ans : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          ); // Ajuste o segundo valor de Y conforme necessário para centralizar

          inicioTexto = 32; // posição X(largura) onde o texto começa
          fimTexto = 20; //posição Y(altura) onde o texto começa
          larguraRetangulo = 63; // largura do retângulo
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text(
            `${data.nr_guia_operador ? data.nr_guia_operador : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 96;
          fimTexto = 20;
          larguraRetangulo = 35;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text(
            `${UtilsPDF.formatDate(data?.senha_data_inicio)}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 132;
          fimTexto = 20;
          larguraRetangulo = 55;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text(
            `${data.senha ? data.senha : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 188;
          fimTexto = 20;
          larguraRetangulo = 45;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text(
            `${UtilsPDF.formatDate(data?.senha_data_validade)}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 234;
          fimTexto = 20;
          larguraRetangulo = 60;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text(
            `${data.nr_guia_operador ? data.nr_guia_operador : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          // Dados do Beneficiário
          let fimFundo = 29;
          let alturaFundo = 5;

          doc.setFont("helvetica", "bold");
          doc.setFontSize(7);
          doc.setTextColor(0, 0, 0);
          textOffset = fimFundo + alturaFundo / 2 + 0.5;

          inicioTexto = 3;
          fimTexto = 35;
          larguraRetangulo = 55;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text(
            `${data.nr_carteirinha ? data.nr_carteirinha : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 59;
          fimTexto = 35;
          larguraRetangulo = 50;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text(
            `${UtilsPDF.formatDate(data?.validade)}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 110;
          fimTexto = 35;
          larguraRetangulo = 92;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text(
            `${data.paciente ? data.paciente : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 203;
          fimTexto = 35;
          larguraRetangulo = 50;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text(
            `${data.paciente_carteira_sus ? data.paciente_carteira_sus : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 254;
          fimTexto = 35;
          larguraRetangulo = 40;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.text("N", inicioTexto + 1, fimTexto + textOffset + 6);

          // Dados Solicitante
          fimFundo = 44; // Posição Y onde o fundo começa
          alturaFundo = 5; // Altura do fundo, ajuste conforme necessário

          doc.setFont("helvetica", "bold");
          doc.setFontSize(7); // Tamanho da fonte para o texto dentro do fundo colorido
          doc.setTextColor(0, 0, 0); // Defina a cor do texto para preto
          textOffset = fimFundo + alturaFundo / 2 + 0.5;

          inicioTexto = 3;
          fimTexto = 50;
          larguraRetangulo = 55;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text(
            `${data.convenio_codigo ? data.convenio_codigo : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 59;
          fimTexto = 50;
          larguraRetangulo = 235;

          doc.text(
            `${data.empresa_nome ? data.empresa_nome : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 3;
          fimTexto = 59;
          larguraRetangulo = 71;

          doc.text(
            `${medicoPaciente.first_name
              ? medicoPaciente.first_name + " " + medicoPaciente.last_name
              : ""
            }`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 75;
          fimTexto = 59;
          larguraRetangulo = 32;

          doc.text(
            `${medicoPaciente.conselho ? medicoPaciente.conselho.nome : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 108;
          fimTexto = 59;
          larguraRetangulo = 32;

          doc.text(
            `${medicoPaciente.nconselho ? medicoPaciente.nconselho : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 141;
          fimTexto = 59;
          larguraRetangulo = 30;

          doc.text(
            `${medicoPaciente.uf_inscricao_conselho
              ? medicoPaciente.uf_inscricao_conselho.sigla
              : ""
            }`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 172;
          fimTexto = 59;
          larguraRetangulo = 30;

          doc.text(
            `${medicoPaciente.especialidade
              ? medicoPaciente.especialidade.cbo
              : ""
            }`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 203;
          fimTexto = 59;
          larguraRetangulo = 91;
          doc.text("", inicioTexto + 1, fimTexto + textOffset + 6);

          // Dados da Solicitação / Procedimento ou Itens Assistenciais Solicitados
          fimFundo = 68; // Posição Y onde o fundo começa
          alturaFundo = 5; // Altura do fundo, ajuste conforme necessário

          doc.setFont("helvetica", "bold");
          doc.setFontSize(7); // Tamanho da fonte para o texto dentro do fundo colorido


          inicioTexto = 3;
          fimTexto = 74;
          larguraRetangulo = 40;

          doc.text("1", inicioTexto + 1, fimTexto + textOffset + 6);

          inicioTexto = 44;
          fimTexto = 74;
          larguraRetangulo = 50;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text(
            `${UtilsPDF.formatDate(data?.senha_data_inicio)}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 95;
          fimTexto = 74;
          larguraRetangulo = 199;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text(
            `${data?.cid}` + ` -- ` + `${data?.cid_descricao}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 3;
          fimTexto = 83;
          larguraRetangulo = 291;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);
          doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);
          doc.text(
            `${item?.procedimento?.tabela_tabela ?? ''}`,
            inicioTexto + 4,
            fimTexto + textOffset + 6
          );

          doc.text(
            `${item.procedimento.codigo}`,
            inicioTexto + 22,
            fimTexto + textOffset + 6
          );

          doc.text(
            `${item.procedimento.procedimento}`,
            inicioTexto + 60,
            fimTexto + textOffset + 6
          );

          const qtd = this.quant

          doc.text(
            `${qtd ? qtd : ""}`,
            inicioTexto + 240,
            fimTexto + textOffset + 6
          );

          doc.text(
            `${qtd ? qtd : ""}`,
            inicioTexto + 265,
            fimTexto + textOffset + 6
          );

          // Dados do Contratado Executante
          fimFundo = 97;
          alturaFundo = 5;

          doc.setFont("helvetica", "bold");
          doc.setFontSize(7);
          doc.setTextColor(0, 0, 0);
          textOffset = fimFundo + alturaFundo / 2 + 0.5;

          inicioTexto = 3;
          fimTexto = 103;
          larguraRetangulo = 45;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text(
            `${data.convenio_codigo ? data.convenio_codigo : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 49;
          fimTexto = 103;
          larguraRetangulo = 214;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text(
            `${data.empresa_nome ? data.empresa_nome : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          inicioTexto = 264;
          fimTexto = 103;
          larguraRetangulo = 30;
          textOffset = (alturaRetangulo - 6) / 2;

          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text(
            `${data.empresa_cnes ? data.empresa_cnes : ""}`,
            inicioTexto + 1,
            fimTexto + textOffset + 6
          );

          // Dados do Atendimento
          fimFundo = 112;
          alturaFundo = 5;

          doc.setFont("helvetica", "bold");
          doc.setFontSize(7);
          doc.setTextColor(0, 0, 0);
          textOffset = fimFundo + alturaFundo / 2 + 0.5;

          inicioTexto = 3;
          fimTexto = 118;
          larguraRetangulo = 30;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("6", inicioTexto + 1, fimTexto + textOffset + 6);

          inicioTexto = 34;
          fimTexto = 118;
          larguraRetangulo = 80;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("9", inicioTexto + 1, fimTexto + textOffset + 6);

          inicioTexto = 115;
          fimTexto = 118;
          larguraRetangulo = 30;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 6);

          inicioTexto = 146;
          fimTexto = 118;
          larguraRetangulo = 70;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 6);

          // Dados da Execução / Procedimentos e Exames Realizados
          fimFundo = 127;
          alturaFundo = 5;

          doc.setFont("helvetica", "bold");
          doc.setFontSize(7);
          doc.setTextColor(0, 0, 0);
          textOffset = fimFundo + alturaFundo / 2 + 0.5;

          inicioTexto = 3;
          fimTexto = 133;
          larguraRetangulo = 291;
          doc.setDrawColor(0);
          doc.setLineWidth(0.1);
          doc.roundedRect(
            inicioTexto,
            fimTexto,
            larguraRetangulo,
            14,
            raioBorda,
            raioBorda
          );
          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);
          doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);
          doc.text("", inicioTexto + 4, fimTexto + textOffset + 6);
          doc.text(``, inicioTexto + 22, fimTexto + textOffset + 6);
          doc.text(``, inicioTexto + 40, fimTexto + textOffset + 6);
          doc.text("", inicioTexto + 60, fimTexto + textOffset + 6);

          doc.text(``, inicioTexto + 185, fimTexto + textOffset + 6);
          doc.text("", inicioTexto + 205, fimTexto + textOffset + 6);
          doc.text("", inicioTexto + 220, fimTexto + textOffset + 6);
          doc.text("", inicioTexto + 245, fimTexto + textOffset + 6);
          doc.text("", inicioTexto + 270, fimTexto + textOffset + 6);

          // Identificação do(s) Profissional(is) Executante(s)
          fimFundo = 148;
          alturaFundo = 5;

          doc.setFont("helvetica", "bold");
          doc.setFontSize(7);
          doc.setTextColor(0, 0, 0);
          textOffset = fimFundo + alturaFundo / 2 + 0.5;


          inicioTexto = 3;
          fimTexto = 154;
          larguraRetangulo = 291;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("1º", inicioTexto + 1, fimTexto + textOffset + 6);
          doc.text("", inicioTexto + 4, fimTexto + textOffset + 6);

          doc.text("", inicioTexto + 20, fimTexto + textOffset + 6);

          doc.text(
            `${item?.profissional?.cpf ?? ""}`,
            inicioTexto + 40,
            fimTexto + textOffset + 6
          );

          doc.text(
            `${item?.profissional?.nomecompleto ?? ""}`,
            inicioTexto + 80,
            fimTexto + textOffset + 6
          );

          doc.text(
            `${item?.profissional?.conselho.nome ?? ""}`,
            inicioTexto + 185,
            fimTexto + textOffset + 6
          );

          doc.text(
            `${item?.profissional?.nconselho ?? ""}`,
            inicioTexto + 220,
            fimTexto + textOffset + 6
          );

          doc.text(
            `${item?.profissional?.uf_inscricao_conselho?.sigla ?? ""}`,
            inicioTexto + 250,
            fimTexto + textOffset + 6
          );

          doc.text(
            `${""}`,
            inicioTexto + 270,
            fimTexto + textOffset + 6
          );

          inicioTexto = 3;
          fimTexto = 167;
          larguraRetangulo = 291;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          // Campo de Observação
          fimFundo = 184;
          alturaFundo = 5;

          doc.setFont("helvetica", "bold");
          doc.setFontSize(7);
          doc.setTextColor(0, 0, 0);
          textOffset = fimFundo + alturaFundo / 2 + 0.5;

          // Parte de Resumo Valores
          inicioTexto = margin + 1;
          fimTexto = 190;
          larguraRetangulo = 40;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = 44;
          fimTexto = 190;
          larguraRetangulo = 40;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = 85;
          fimTexto = 190;
          larguraRetangulo = 40;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = 126;
          fimTexto = 190;
          larguraRetangulo = 40;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = 167;
          fimTexto = 190;
          larguraRetangulo = 40;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = 208;
          fimTexto = 190;
          larguraRetangulo = 40;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = 249;
          fimTexto = 190;
          larguraRetangulo = 45;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = margin + 1;
          fimTexto = 199;
          larguraRetangulo = 90;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = 94;
          fimTexto = 199;
          larguraRetangulo = 100;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

          inicioTexto = 195;
          fimTexto = 199;
          larguraRetangulo = 99;

          textOffset = (alturaRetangulo - 6) / 2;
          doc.setFont("helvetica", "normal");
          doc.setFontSize(7);

          doc.text("", inicioTexto + 1, fimTexto + textOffset + 5);

        } catch (error) {
          console.error("Erro ao carregar os dados para o PDF:", error);
          this.$toast.error("Erro ao carregar os dados:", error);
        } finally {
          this.loading = false; // Desativa o indicador de carregamento independentemente do sucesso ou falha
        }
      }

    },
    async getOrcamento() {
      try {
        const { data } = await api.get(
          `atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`
        );
        this.orcamento = data[0];
        if (
          this.orcamento &&
          (this.orcamento.paciente_medico_id ||
            this.orcamento.empresa_medico_responsavel_id)
        ) {
          await this.getMedico();
        }
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do relatório:", error);
        throw error;
      }
    },
    async getMedico() {
      try {
        const idMedico =
          this.orcamento.paciente_medico_id ||
          this.orcamento.empresa_medico_responsavel_id;
        const { data } = await api.get(`contas/usuario/${idMedico}/`);
        this.medico = data;
        // console.log(this.medico)

      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do médico:", error);
      }
    },
    async getProcedimentos() {
      try {
        const { data } = await api.get(
          `atendimentos/evolucoes/?sessao=${this.selectedBudgets[0]}`
        );
        // console.log(data)
        // Filtra os procedimentos onde cobranca é true, o desconto é diferente de 100% e temn assinatura e está ativo a evolucao.
        const filteredData = data.filter(item => item.ativo === true && item.procedimento.especial === false);
        // Ordenando os procedimentos por timestamp em ordem crescente
        this.procedimentos = filteredData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        // console.log('Procedimentos --> ', this.procedimentos)
        const counter = this.procedimentos.reduce((acc, item) => {
          const id = item.procedimento.procedimento_id;
          if (!acc[id]) {
            acc[id] = { procedimento_id: id, qtd: 0 };
          }
          acc[id].qtd += 1;
          return acc;
        }, {});

        this.qtd = Object.values(counter);
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(
          `Desculpa, aconteceu alguma coisa errada. Erro -> ${error}`
        );
      }
    },
    getBlankSpace(width, doc) {
      const spaceWidth = doc.getTextWidth(' ');
      const spaceCount = Math.ceil(width / spaceWidth);
      return ' '.repeat(spaceCount);
    }
  },
}
</script>